import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import Cookies from 'js-cookie';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { Box, Typography, Paper, CircularProgress, Alert, Container, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import Navbar from './include/Navbar';
const API_URL = process.env.REACT_APP_API_URL;

const Subscription = () => {
    const { plan } = useParams();
    const [planDetails, setPlanDetails] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate()

    useEffect(() => {
        if (plan && plan === "free") {
            navigate("/login");
        }

        axios
            .get(`${API_URL}api/plans/plans/${plan}/`)
            .then((response) => {
                setPlanDetails(response.data);
                setLoading(false);
            })
            .catch(() => {
                setError('Failed to load plan details');
                setLoading(false);
            });
    }, [plan]);

    if (loading) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    height: '100vh',
                }}
            >
                <Alert severity="error">{error}</Alert>
            </Box>
        );
    }

    console.log(planDetails)

    return (
        <>
        <Navbar/>
        <Container maxWidth="sm" sx={{ mt: 4 }}>
            <Paper elevation={3} sx={{ p: 4 }}>
                <Typography variant="h4" gutterBottom>
                    Subscribe to {planDetails.name} Plan
                </Typography>
                <Typography variant="h6" color="text.secondary" gutterBottom>
                    Price: {planDetails.price} {planDetails.currency}
                </Typography>
                <Typography variant="body1" sx={{ mb: 4 }}>
                    {planDetails.description}
                </Typography>

                <PayPalScriptProvider
                    options={{
                        "client-id": process.env.REACT_APP_PAYPAL_CLIENT,
                        vault: true,
                        intent: "subscription",
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        <PayPalButtons
                            style={{ layout: 'vertical', color: 'gold', label: 'subscribe' }}
                            createSubscription={(data, actions) => {
                                return actions.subscription.create({
                                    plan_id: planDetails.paypal_plan.paypal_id,
                                });
                            }}
                            onApprove={(data) => {
                                const subscriptionDetails = {
                                    subscription_id: data.subscriptionID,
                                    plan_id: planDetails.id,
                                    start_date: new Date().toISOString(),
                                    status: 'Active',
                                    next_billing_date: null,
                                };

                                axios
                                    .post(
                                        `${process.env.REACT_APP_API_URL}/api/plans/subscribe/`,
                                        subscriptionDetails,
                                        {
                                            headers: {
                                                Authorization: `Bearer ${Cookies.get('access_token')}`,
                                            },
                                        }
                                    )
                                    .then((response) => {
                                        console.log('Subscription saved:', response.data);
                                    })
                                    .catch((error) => {
                                        console.error('Failed to save subscription:', error.response.data);
                                    });
                            }}
                            onError={(err) => {
                                console.error('PayPal error:', err);
                            }}
                        />
                    </Box>
                </PayPalScriptProvider>
            </Paper>
        </Container>
        </>
    );
};

export default Subscription;
