import React, { useEffect, useState, useRef } from "react";
import { AppBar, Toolbar, Button } from "@mui/material";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import attractions from "./touristic_attractions";
import L from "leaflet";

const customIcon = new L.Icon({
    iconUrl: 'https://img.icons8.com/?size=100&id=7880&format=png&color=FF0000',
    iconSize: [32, 32], 
    iconAnchor: [16, 32], 
    popupAnchor: [0, -32],
});

const Homepage = () => {
    const [currentAttractionIndex, setCurrentAttractionIndex] = useState(0);
    const mapRef = useRef(null); 

    const intervalTime = 5000; 

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentAttractionIndex((prevIndex) => {
                const nextIndex = (prevIndex + 1) % attractions.length;
                return nextIndex;
            });
        }, intervalTime);

        return () => clearInterval(interval);
    }, []);

    const currentAttraction = attractions[currentAttractionIndex];

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.flyTo(currentAttraction.coordinates, 14, {
                animate: true, 
                duration: 1.5,  
            });
        }
    }, [currentAttraction]);
    

    const url = process.env.PUBLIC_URL;

    return (
        <div style={{ position: "relative", height: "100vh", width: "100vw" }}>
            <MapContainer
                style={{ height: "100%", width: "100%" }}
                center={currentAttraction.coordinates} // Start with the first attraction
                zoom={14}
                minZoom={10}
                zoomControl={false}
                ref={mapRef}
            >
                <TileLayer
                    url="https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}{r}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>'
                    subdomains="abcd"
                    maxZoom={20}
                />

                <Marker
                    position={currentAttraction.coordinates}
                    icon={customIcon}
                >
                    <Popup>
                        <strong>{currentAttraction.name}</strong>
                        <br />
                        {currentAttraction.location}
                        <br />
                        Visitors: {currentAttraction.visitors}
                    </Popup>
                </Marker>
            </MapContainer>

            <AppBar
                position="absolute"
                style={{
                    background: "rgba(0, 0, 0, 0.5)",
                    boxShadow: "none",
                }}
            >
                <Toolbar style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <Button href="/plans" color="inherit" style={{ color: "#fff" }}>
                            Plans
                        </Button>
                        <Button href="/map" color="inherit" style={{ color: "#fff" }}>
                            Map
                        </Button>
                        <Button href="/contact" color="inherit" style={{ color: "#fff" }}>
                            Contact
                        </Button>
                    </div>
                    <Button href="/login" color="inherit" style={{ color: "#fff", marginLeft: "auto" }}>
                        Login
                    </Button>
                </Toolbar>
            </AppBar>

            <div style={{
                position: "absolute",
                top: "20%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                zIndex: 1000,
                textAlign: "center",
            }}>
                <img
                    className="img-logo"
                    src={url + "/logo-world.svg"}
                    alt="Web GIS Logo"
                    style={{ height: "100px", marginBottom: "20px" }}
                />
            </div>

            <div
                style={{
                    position: "absolute",
                    top: "30%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    zIndex: 1000,
                    textAlign: "center",
                }}
            >
                <Button
                    href="/login"
                    variant="contained"
                    color="primary"
                    style={{ padding: "10px 20px", fontSize: "16px" }}
                >
                    Login
                </Button>
            </div>
        </div>
    );
};

export default Homepage;
