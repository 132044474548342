import React, { useState } from 'react';
import NavbarComponent from './include/Navbar';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress, Box } from '@mui/material';
import { ProfileSettings } from './utils/profile/Settings';
import { ProfileAccount } from './utils/profile/Account';
import { ProfileUserInformation } from './utils/profile/UserInformation';

const Dashboard = () => {
  const { user, loading } = useSelector(state => state.user);
  const [activeSection, setActiveSection] = useState('userInformation');
  const { t } = useTranslation();

  const renderActiveSection = () => {
    switch (activeSection) {
      case 'userInformation':
        return <ProfileUserInformation/>

      case 'settings':
        return <ProfileSettings/>;

      case 'account':
        return <ProfileAccount/>

      default:
        return null;
    }
  };

  return (
    <>
      <NavbarComponent />
      {loading || user === null ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', height: '100vh' }}>
          <Box sx={{ 
              width: 250, 
              backgroundColor: '#f4f4f4', 
              padding: 2, 
              boxShadow: 1,
              
            }}>
            <Button 
              sx={{
                color:"black",
                textAlign: 'left', 
                justifyContent: 'flex-start', 
                textTransform: 'none',
              }} 
              fullWidth variant="text" onClick={() => setActiveSection('userInformation')}>
              {t('user_information')}
            </Button>
            <Button 
              sx={{
                color:"black",
                textAlign: 'left', 
                justifyContent: 'flex-start', 
                textTransform: 'none',
              }} 
              fullWidth variant="text" onClick={() => setActiveSection('settings')}>
              {t('settings')}
            </Button>
            <Button 
              sx={{
                color:"black",
                textAlign: 'left', 
                justifyContent: 'flex-start', 
                textTransform: 'none',
              }} 
            fullWidth variant="text" onClick={() => setActiveSection('account')}>
              {t('account')}
            </Button>
          </Box>

          <Box sx={{ flex: 1, padding: 3 }}>
            {renderActiveSection()}
          </Box>
        </Box>
      )}
    </>
  );
};

export default Dashboard;


// import React, { useState } from 'react';
// import NavbarComponent from './include/Navbar';
// import { useSelector, useDispatch } from 'react-redux';
// import { deleteUser, logout } from '../features/user';
// import { useTranslation } from 'react-i18next';
// import { Menu, MenuItem, Card, CardContent, Typography, Button, CircularProgress, Avatar, Box } from '@mui/material';
// import i18n from '../i18n/i18n';

// const formatDataSize = (sizeInBytes) => {
//   if (sizeInBytes < 1024) {
//     return `${sizeInBytes} B`;
//   } else if (sizeInBytes < 1048576) { // 1024 * 1024
//     return `${(sizeInBytes / 1024).toFixed(2)} KB`;
//   } else if (sizeInBytes < 1073741824) { // 1024 * 1024 * 1024
//     return `${(sizeInBytes / 1048576).toFixed(2)} MB`;
//   } else {
//     return `${(sizeInBytes / 1073741824).toFixed(2)} GB`;
//   }
// };

// const url = process.env.PUBLIC_URL;

// const languages = {
//   "en-US": <img className='icon-nav-lan' src={url + "/estados-unidos.png"} alt="US Flag" />,
//   "pt-BR": <img className='icon-nav-lan' src={url + "/brasil.png"} alt="Brazil Flag" />,
// };



// function Dashboard() {
//   const dispatch = useDispatch();
//   const [confirmDelete, setConfirmDelete] = useState(false);
//   const { user, loading } = useSelector(state => state.user);
//   const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = useState(null);
//   const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);
//   const { t } = useTranslation();

//   const profile = user && user.profile;

  
//   const handleLanguageMenu = (event) => {
//     setLanguageMenuAnchorEl(event.currentTarget);
//   };

//   const handleCloseProfileMenu = () => {
//       setProfileMenuAnchorEl(null);
//   };

//   const handleCloseLanguageMenu = () => {
//       setLanguageMenuAnchorEl(null);
//   };

//   const changeLanguage = (lng) => {
//     i18n.changeLanguage(lng);
//     handleCloseLanguageMenu();
//   };

//   const LanguageMenu = (
//     <Menu
//         anchorEl={languageMenuAnchorEl}
//         open={Boolean(languageMenuAnchorEl)}
//         onClose={handleCloseLanguageMenu}
//     >
//         <MenuItem onClick={() => { changeLanguage('en-US'); }}>{languages["en-US"]}</MenuItem>
//         <MenuItem onClick={() => { changeLanguage('pt-BR'); }}>{languages["pt-BR"]}</MenuItem>
//     </Menu>
//   );

//   const handleDeleteUser = () => {
//     if (confirmDelete) {
      
//       dispatch(deleteUser(user.id))
//         .unwrap()
//         .then(() => {
//           dispatch(logout());
//         })
//         .catch((error) => {
//           alert(error.detail);
//         });
//     } else {
//       setConfirmDelete(true);
//     }
//   };

//   return (
//     <>
//       <NavbarComponent />
//       {loading || user === null ? (
//         <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
//           <CircularProgress />
//         </Box>
//       ) : (
//         <Box sx={{ padding: 3 }}>
//           <Typography variant="h4" gutterBottom align="center">
//             {t('profile')}
//           </Typography>
//           <Card sx={{ maxWidth: 500, margin: 'auto', padding: 2 }}>
//             <CardContent>
//               <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//                 <Avatar
//                   sx={{ width: 100, height: 100, mb: 2 }}
//                   src={profile.profile_picture || '/default-profile.png'}
//                 />
//                 <Typography variant="h6">{user.username}</Typography>
//                 <Typography variant="subtitle1" color="textSecondary">{user.email}</Typography>
//                 <Typography variant="body1" color="textSecondary" mt={2}>
//                   {t('data_size_raster')}: {formatDataSize(profile.total_raster_usage)}
//                 </Typography>
//                 <Typography variant="body1" color="textSecondary" mt={2}>
//                   {t('data_size_vector')}: {formatDataSize(profile.total_vector_usage)}
//                 </Typography>
//                 <Typography variant="body2" color="textSecondary" mt={2}>
//                   {profile.bio || t('no_bio')}
//                 </Typography>
//               </Box>
//             </CardContent>
//           </Card>
  
//           {/* Language Switcher Button */}
//           <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
//             <Button
//               variant="outlined"
//               color="primary"
//               onClick={handleLanguageMenu}
//               sx={{ mb: 2 }}
//             >
//               {t('change_language')}
//             </Button>
//             {LanguageMenu}
//           </Box>
  
//           <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
//             {!confirmDelete ? (
//               <Button
//                 variant="contained"
//                 color="primary"
//                 onClick={() => setConfirmDelete(true)}
//               >
//                 {t('delete_user')}
//               </Button>
//             ) : (
//               <Box sx={{ textAlign: 'center' }}>
//                 <Typography variant="body1">{t('he_is_sure')}</Typography>
//                 <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
//                   <Button
//                     variant="contained"
//                     color="error"
//                     onClick={handleDeleteUser}
//                     sx={{ mr: 2 }}
//                   >
//                     {t('yes')}
//                   </Button>
//                   <Button
//                     variant="outlined"
//                     color="secondary"
//                     onClick={() => setConfirmDelete(false)}
//                   >
//                     {t('no')}
//                   </Button>
//                 </Box>
//               </Box>
//             )}
//           </Box>
//         </Box>
//       )}
//     </>
//   );  
// }

// export default Dashboard;
