import Cookies from 'js-cookie'
import axios from 'axios';
import M from 'materialize-css';
import { useState, useEffect } from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Table,
  TableBody,
  TableCell,
  Grid,
  TableRow,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  DialogActions,
  Tooltip
} from "@mui/material"
import FormatColorFillIcon from '@mui/icons-material/FormatColorFill';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import GlobalStyle from './stylecontrols/GlobalStyle';
import CategorizedStyle from './stylecontrols/CategorizedStyle';

import "./StyleControls.css"

const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000/'


const ModalChangeData = ({ geojson, updateStyle, updateStyleCat,setIsModalOpen }) => {
  const [selectedOption, setSelectedOption] = useState('global');

  const handleOptionChange = (event, newOption) => {
    if (newOption !== null) {
      setSelectedOption(newOption);
    }
  };

  return (
    <Box sx={{ textAlign: 'center', p: 2 }}>
      <Box sx={{ mb: 2 }}>Choose Style</Box>
      <ToggleButtonGroup
        value={selectedOption}
        exclusive
        onChange={handleOptionChange}
        aria-label="style selection"
        sx={{ justifyContent: 'center' }}
      >
        <ToggleButton value="global" aria-label="global">
          Global
        </ToggleButton>
        <ToggleButton value="category" aria-label="category">
          Categorized
        </ToggleButton>
      </ToggleButtonGroup>

      <Box mt={3}>
        {selectedOption === 'global' ? (
          <GlobalStyle geojson={geojson} updateStyle={updateStyle} setIsModalOpen={setIsModalOpen} />
        ) : (
          <CategorizedStyle geojson={geojson} updateStyle={updateStyleCat} setIsModalOpen={setIsModalOpen} />
        )}
      </Box>
    </Box>
  );
};

export const get_item_table = (title, inputType, value, name, geojson, updateStyle) => {

  const onChange = e => updateStyle(
    geojson.properties.id, name, e.target.value
  )
  const isRange = inputType === 'range';

  let min, max, step;
  let classname

  if (name === 'weight') {
    min = 0; max = 10; step = 1;
  } else if (name === 'fillOpacity') {
    min = 0; max = 1; step = 0.1;
  }
  else {
    min = undefined; max = undefined; step = undefined;
  }


  if (isRange) {
    classname = `sidenav-range-style`
  }
  else {
    classname = `input-color-style`
  }


  return (
    <tr>
      <td><span>{title}</span></td>
      <td className='alnright'>
        <input
          className={classname}
          type={inputType}
          value={value}
          onChange={onChange}
          min={min}
          max={max}
          step={step}
        />
      </td>
    </tr>
  )
}

export const StyleControls = ({
  geojsondata,
  updateStyle,
  updateStyleCat,
  inmemory=false
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openStyleModal = () => {
    setIsModalOpen(true);
  };

  const closeStyleModal = () => {
    setIsModalOpen(false);
  };


  return (
    <>
      <Tooltip 
          title={inmemory ? "Function only allowed for logged users" : "A"} 
          placement="right"
      >   
        <span>
          <MenuItem onClick={openStyleModal} disabled={inmemory} >
              <FormatColorFillIcon />
              <span style={{ marginLeft: 8 }}>Change style</span>
          </MenuItem>
        </span>
      </Tooltip>

      <Dialog open={isModalOpen} onClose={closeStyleModal}>
        <DialogTitle >Change Style</DialogTitle>
        <DialogContent>
          <ModalChangeData
            geojson={geojsondata}
            updateStyle={updateStyle}
            updateStyleCat={updateStyleCat}
            setIsModalOpen={setIsModalOpen}
          />
        </DialogContent>
      </Dialog>
    </>
  );
};

const changeVisual = async (rasters, setRasters, raster_id, visual_type, params) => {
  try {
    const response = await axios.post(
      `${API_URL}api/main/raster/change-visual/${raster_id}/`,
      {
        params: params,
        visual_type: visual_type
      },
      {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${Cookies.get('access_token')}`
        }
      }
    );

    const updatedRasters = rasters.map(raster => {
      if (raster.data.id === raster_id) {
        return { ...raster, data: { ...raster.data, png: response.data.png } }
      }
      return raster;
    });
    setRasters(updatedRasters)
    // // console.log(response)

    if (response.status === 201) {
      M.toast(
        {
          html: "Raster updated sucessfully.",
          classes: 'green rounded',
          displayLength: 5000
        });
    } else {
      M.toast(
        {
          html: "Error while trying to update raster",
          classes: 'red rounded',
          displayLength: 5000
        })
    }


    return response

  } catch (error) {
    console.log(error)
  }
}

export const StyleRasterControls = ({
  rasters,
  setRasters,
  bands,
  raster_id,
  // open, 
  // handleClose,
}) => {
  const [selectedValues, setSelectedValues] = useState({ R: '', G: '', B: '', Gray: '' });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openStyleModal = () => {
    setIsModalOpen(true);
  };

  const closeStyleModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmitComposition = () => {
    const visual_type = "composition";

    const params = {
      R: selectedValues['R'],
      G: selectedValues['G'],
      B: selectedValues['B']
    };

    console.log(selectedValues['R'])
    console.log(selectedValues['G'])
    console.log(selectedValues['B'])

    if (selectedValues['R']==="" || selectedValues['G']==="" || selectedValues['B']==="") {
      alert('Please select values for R, G, and B.');
      return;
    }

    changeVisual(rasters, setRasters, raster_id, visual_type, params);
  };

  const handleSubmitGrayscale = () => {
    const visual_type = "grayscale";
    const params = {
      Gray: selectedValues['Gray'],
    };

    if (!selectedValues['Gray']) {
      alert('Please select the band.');
      return;
    }

    changeVisual(rasters, setRasters, raster_id, visual_type, params);
  };

  const selectItems = (key) => (
    <Select
      value={selectedValues[key]}
      onChange={(e) => setSelectedValues({ ...selectedValues, [key]: e.target.value })}
      displayEmpty
      fullWidth
    >
      <MenuItem value="" disabled>
        Choose your option
      </MenuItem>
      {Array.from({ length: bands }, (_, index) => (
        <MenuItem key={index} value={index}>
          {`Band ${index + 1}`}
        </MenuItem>
      ))}
    </Select>
  );

  const compositionControls = (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={4}>
        <Typography>Red</Typography>
      </Grid>
      <Grid item xs={8}>
        {selectItems('R')}
      </Grid>
      <Grid item xs={4}>
        <Typography>Green</Typography>
      </Grid>
      <Grid item xs={8}>
        {selectItems('G')}
      </Grid>
      <Grid item xs={4}>
        <Typography>Blue</Typography>
      </Grid>
      <Grid item xs={8}>
        {selectItems('B')}
      </Grid>
    </Grid>
  );

  const grayscaleControls = (
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={4}>
        <Typography>Grayscale</Typography>
      </Grid>
      <Grid item xs={8}>
        {selectItems('Gray')}
      </Grid>
    </Grid>
  );

  const bandsItem = (
    <Box mt={2}>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Composition</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {compositionControls}
          <Box mt={2} textAlign="center">
            <Button
              onClick={handleSubmitComposition}
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Grayscale</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {grayscaleControls}
          <Box mt={2} textAlign="center">
            <Button
              onClick={handleSubmitGrayscale}
              variant="contained"
              color="primary"
            >
              Submit
            </Button>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );

  return (
    <>
    <MenuItem onClick={openStyleModal} >
          <EditIcon />
          <span style={{ marginLeft: 8 }}>Change style</span>
      </MenuItem>
    <Dialog open={isModalOpen} onClose={closeStyleModal} maxWidth="sm" fullWidth>
      <DialogTitle>
        Select Bands for Visualization
        <IconButton
          aria-label="close"
          onClick={closeStyleModal}
          sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        {bands > 0 && bandsItem}
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={closeStyleModal}
          color="secondary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
    </>
  );
};

