import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { register } from '../features/user';
import { Navigate } from 'react-router-dom';
import M from 'materialize-css';
import { 
    TextField, 
    Button, 
    Box, 
    Typography, 
    Card, 
    CardContent, 
    Alert, 
    Snackbar, 
    CircularProgress 
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import '../styles/Register.css';

const theme = createTheme({
    palette: {
        primary: {
            main: '#d32f2f',
        },
        secondary: {
            main: '#ffffff',
        },
    },
});

function Register() {
    const dispatch = useDispatch();
    const [registerError, setRegisterError] = useState('');
    const [formData, setFormData] = useState({
        username: '',
        email: '',
        password: '',
        password2: '',
    });
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [redirect, setRedirect] = useState(false); // Controls when to navigate

    useEffect(() => {
        M.AutoInit();
    }, []);

    const { username, email, password, password2 } = formData;

    const onChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const onSubmit = e => {
        e.preventDefault();
        setLoading(true);
        dispatch(register({ username, email, password, password2 }))
            .then(data => {
                setLoading(false);
                if (data.meta.requestStatus === 'rejected') {
                    const errors = Object.values(data.payload).flat();
                    setRegisterError(errors.join(' '));
                    errors.forEach(error => {
                        M.toast({
                            html: error,
                            classes: 'red rounded',
                            displayLength: 10000,
                        });
                    });
                } else {
                    setSnackbarOpen(true); // Open snackbar on success
                }
            })
            .catch(error => {
                setLoading(false);
                console.error('Register error:', error);
            });
    };

    if (redirect) return <Navigate to="/login" />;

    var url = process.env.PUBLIC_URL;

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    minHeight: '100vh',
                    backgroundColor: '#f5f5f5',
                }}
            >
                <Card sx={{ width: 400, padding: 3 }}>
                    <CardContent>
                        <Box sx={{ textAlign: 'center', mb: 2 }}>
                            <a href="/" className="brand-logo">
                                <img src={`${url}/logo-world.svg`} alt="Web GIS Logo" height={80} />
                            </a>
                            <Typography variant="h5" color="primary" sx={{ mt: 2 }}>
                                Cadastro
                            </Typography>
                        </Box>
                        <form onSubmit={onSubmit}>
                            <TextField
                                fullWidth
                                label="Usuário"
                                name="username"
                                value={username}
                                onChange={onChange}
                                margin="normal"
                                variant="outlined"
                                color="primary"
                            />
                            <TextField
                                fullWidth
                                label="Email"
                                type="email"
                                name="email"
                                value={email}
                                onChange={onChange}
                                margin="normal"
                                variant="outlined"
                                color="primary"
                            />
                            <TextField
                                fullWidth
                                label="Senha"
                                type="password"
                                name="password"
                                value={password}
                                onChange={onChange}
                                margin="normal"
                                variant="outlined"
                                color="primary"
                            />
                            <TextField
                                fullWidth
                                label="Confirmar senha"
                                type="password"
                                name="password2"
                                value={password2}
                                onChange={onChange}
                                margin="normal"
                                variant="outlined"
                                color="primary"
                            />
                            {registerError && (
                                <Alert severity="error" sx={{ mt: 2 }}>
                                    {registerError}
                                </Alert>
                            )}
                            <Button
                                type="submit"
                                fullWidth
                                variant="contained"
                                color="primary"
                                sx={{ mt: 3, mb: 2, textTransform: 'none' }}
                                disabled={loading}
                            >
                                {loading ? <CircularProgress size={24} color="secondary" /> : 'Criar conta'}
                            </Button>
                        </form>
                        <Typography variant="body2" align="center">
                            Já tem uma conta?{' '}
                            <a href="/login" style={{ color: theme.palette.primary.main }}>
                                Entrar
                            </a>
                        </Typography>
                    </CardContent>
                </Card>
            </Box>
            {/* Snackbar with confirmation button */}
            <Snackbar
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                message="Please, check your email to finish your registration"
                action={
                    <Button
                        color="secondary"
                        size="small"
                        onClick={() => setRedirect(true)} // Trigger redirect
                    >
                        Continuar
                    </Button>
                }
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            />
        </ThemeProvider>
    );
}

export default Register;
