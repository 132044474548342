import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { deleteUser, logout } from '../../../features/user';

import { useTranslation } from 'react-i18next';
import { Typography, Button, Box } from '@mui/material';





export const ProfileAccount = () => {
    const [confirmDelete, setConfirmDelete] = useState(false);
    const { user, loading } = useSelector(state => state.user);
    const dispatch = useDispatch()
    const { t } = useTranslation();

    const handleDeleteUser = () => {
        if (confirmDelete) {
            dispatch(deleteUser(user.id))
                .unwrap()
                .then(() => {
                    dispatch(logout());
                })
                .catch((error) => {
                    alert(error.detail);
                });
        } else {
            setConfirmDelete(true);
        }
    };

    return (
        <>
            {!confirmDelete ? (
                <Box 
                        sx={{ 
                            display: 'flex', 
                            justifyContent: 'center', 
                            alignItems: 'center', 
                        }}
                    >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => setConfirmDelete(true)}
                    >
                        {t('delete_user')}
                    </Button>
                </Box>
            ) : (
                <Box sx={{ textAlign: 'center' }}>
                    <Typography variant="body1">{t('he_is_sure')}</Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleDeleteUser}
                            sx={{ mr: 2 }}
                        >
                            {t('yes')}
                        </Button>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => setConfirmDelete(false)}
                        >
                            {t('no')}
                        </Button>
                    </Box>
                </Box>
            )}
        </>
    )
}