import React, { useState } from 'react';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem, Drawer, List, ListItem, ListItemText, Hidden, Avatar } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../features/user';
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/i18n';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import './Navbar.css';

const url = process.env.PUBLIC_URL;

const languages = {
    "en-US": <img className='icon-nav-lan' src={url + "/estados-unidos.png"} alt="US Flag" />,
    "pt-BR": <img className='icon-nav-lan' src={url + "/brasil.png"} alt="Brazil Flag" />,
};

const Navbar = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { isAuthenticated, user } = useSelector(state => state.user);

    const [profileMenuAnchorEl, setProfileMenuAnchorEl] = useState(null);
    const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);

    const handleProfileMenu = (event) => {
        setProfileMenuAnchorEl(event.currentTarget);
    };

    const handleLanguageMenu = (event) => {
        setLanguageMenuAnchorEl(event.currentTarget);
    };

    const handleCloseProfileMenu = () => {
        setProfileMenuAnchorEl(null);
    };

    const handleCloseLanguageMenu = () => {
        setLanguageMenuAnchorEl(null);
    };

    const toggleDrawer = (open) => (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    };

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        handleCloseLanguageMenu();
    };

    const LanguageMenu = (
        <Menu
            anchorEl={languageMenuAnchorEl}
            open={Boolean(languageMenuAnchorEl)}
            onClose={handleCloseLanguageMenu}
        >
            <MenuItem onClick={() => { changeLanguage('en-US'); }}>{languages["en-US"]}</MenuItem>
            <MenuItem onClick={() => { changeLanguage('pt-BR'); }}>{languages["pt-BR"]}</MenuItem>
        </Menu>
    );

    const ProfileMenu = (
        <Menu
            anchorEl={profileMenuAnchorEl}
            open={Boolean(profileMenuAnchorEl)}
            onClose={handleCloseProfileMenu}
        >
            {isAuthenticated ? (
                [
                    <MenuItem key="profile" onClick={() => { window.location.href = '/dashboard'; }}>{t('profile')}</MenuItem>,
                    <MenuItem key="signout" onClick={() => { dispatch(logout()); }}>{t('sign_out')}</MenuItem>,
                ]
            ) : (
                [
                    <MenuItem key="login" onClick={() => { window.location.href = '/login'; }}>{t('login')}</MenuItem>,
                    <MenuItem key="register" onClick={() => { window.location.href = '/register'; }}>{t('register')}</MenuItem>,
                ]
            )}
        </Menu>
    );

    const drawerList = (
        <List>
            {isAuthenticated ? (
                <>
                    <ListItem onClick={handleProfileMenu}>
                        <ListItemText primary={t('profile')} />
                    </ListItem>
                    {ProfileMenu}
                </>
            ) : (
                <>
                    {/* <ListItem onClick={() => { window.location.href = '/map'; }}>{t('take_tour')}</ListItem> */}
                    <ListItem onClick={() => { window.location.href = '/login'; }}>{t('login')}</ListItem>
                    <ListItem onClick={() => { window.location.href = '/register'; }}>{t('register')}</ListItem>
                </>
            )}
            {/* <ListItem onClick={handleLanguageMenu}>
                <ListItemText primary={t('language')} />
            </ListItem>
            {LanguageMenu} */}
        </List>
    );

    return (
        <>
            <AppBar position="static" className='nav-wg'>
                <Toolbar style={{ backgroundColor: 'white' }}>
                    <a href="/#" className="brand-logo" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'inherit' }}>
                        <img className="img-logo" src={url + "/logo-world.svg"} alt="Web GIS Logo" style={{ height: '50px', marginLeft: '20px' }} />
                        {/* <Typography variant="h6" className='logo-text' style={{ marginLeft: '10px', fontSize: '25px', color: 'black' }}>
                            WebGIS Project
                        </Typography> */}
                    </a>
                    <Hidden smDown>
                        <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
                            {/* {isAuthenticated ?
                                <MenuItem style={{ color: 'black' }} onClick={() => { window.location.href = '/project'; }}>{t('projects')}</MenuItem> :
                                <MenuItem style={{ color: 'black' }} onClick={() => { window.location.href = '/map'; }}>{t('take_tour')}</MenuItem>
                            } */}


                            {/* <IconButton
                                edge="end"
                                color="inherit"
                                aria-label="language"
                                onClick={handleLanguageMenu}
                                style={{
                                    marginLeft: 'auto',
                                    paddingRight: "40px"
                                }}
                            >
                                {languages[i18n.language]}
                            </IconButton> */}
                            <IconButton
                                edge="end"
                                color="inherit"
                                aria-label="profile-menu"
                                onClick={handleProfileMenu}
                                style={{ marginLeft: 'auto' }}
                            >
                                {isAuthenticated ? (
                                    user?.profile?.profile_picture ? (
                                        <Avatar
                                            src={user.profile.profile_picture}
                                            alt="Profile Picture" />
                                    ) : (
                                        <AccountCircleIcon
                                            style={{
                                                color: 'black',
                                                fontSize: 40
                                            }}
                                        />
                                    )
                                ) : (
                                    <AccountCircleIcon
                                        style={{
                                            color: 'black',
                                            fontSize: 40
                                        }}
                                    />
                                )}
                            </IconButton>
                            {ProfileMenu}
                        </div>
                    </Hidden>
                    <Hidden smUp>
                        <IconButton
                            edge="end"
                            color="inherit"
                            aria-label="menu"
                            onClick={toggleDrawer(true)}
                            style={{ marginLeft: 'auto' }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Drawer
                            anchor="right"
                            open={drawerOpen}
                            onClose={toggleDrawer(false)}
                        >
                            {drawerList}
                        </Drawer>
                    </Hidden>
                </Toolbar>
            </AppBar>
            {/* {LanguageMenu} */}
        </>
    );
};

export default Navbar;

