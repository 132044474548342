import './Loading.css'; 


export const loadingPage = ()=>{
    return(
    <div className="loading-container">
        <div className="spinner"></div>
            <p>Loading...</p>
    </div>
    )
} 



// import './Loading.css';

// export const loadingPage = () => {
//     return (
//         <div className="loading-fullscreen">
//             <img 
//                 src="https://media0.giphy.com/media/v1.Y2lkPTc5MGI3NjExaHZpZWI0cjViaGhqNzAzeDdndXJ2dGxpMWdrOHBwbXV5b2htcGs3bSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/3ohhwgrL4KKPIZoTQY/giphy.webp" 
//                 alt="Loading..." 
//                 className="loading-background" 
//             />
//         </div>
//     );
// };
