import React, { useEffect, useState } from 'react';
import { Container, Grid, Card, CardContent, Typography, Button } from '@mui/material';
import Navbar from './include/Navbar';
import Footer from './include/Footer';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';  

const API_URL = process.env.REACT_APP_API_URL;

const formatStorage = (valueInMB) => {
    return valueInMB >= 1024
        ? `${(valueInMB / 1024).toFixed(1)} GB`
        : `${valueInMB} MB`;
};

const Pricing = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [plans, setPlans] = useState([]); 
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);  

    useEffect(() => {
        axios.get(
            `${API_URL}api/plans/plans/`,
        )  
            .then((response) => {
                setPlans(response.data);
                setLoading(false);  
            })
            .catch((error) => {
                setError('Failed to load plans');  
                setLoading(false); 
            });
    }, []);  

    if (loading) {
        return (
            <div>Loading...</div> 
        );
    }

    if (error) {
        return (
            <div>Error: {error}</div>
        );
    }

    return (
        <>
            <Navbar />
            <div style={{ backgroundColor: '#eceaea', minHeight: '100vh', paddingBottom: '20px', paddingTop: '50px' }}>
                <Container>
                    <Typography variant="h4" align="center" sx={{ paddingBottom: '20px' }}>{t('welcome')}</Typography>
                    <Typography align="center" style={{ whiteSpace: 'pre-line', marginBottom: '40px' }}>
                        {t('choose_access_type')}
                    </Typography>
                    <Grid container spacing={3}>
                        {plans.map((plan, index) => (
                            <Grid item xs={12} sm={6} md={3} key={index}>
                                <Card>
                                    <CardContent>
                                        <Typography variant="h5" align="center" style={{ marginBottom: '10px' }}>
                                            {plan.name}
                                        </Typography>
                                        <Typography variant="h6" align="center" color="primary" style={{ marginBottom: '20px' }}>
                                            {plan.price} {plan.currency}
                                        </Typography>
                                        <Typography variant="body2" align="center" style={{ marginBottom: '10px' }}>
                                            {plan.description}
                                        </Typography>
                                        <Typography variant="body2" align="center" style={{ marginBottom: '10px' }}>
                                            <strong>{t("data_transfer")}:</strong> {formatStorage(plan.data_transfer)}/month
                                        </Typography>
                                        <Typography variant="body2" align="center" style={{ marginBottom: '10px' }}>
                                            <strong>{t("vector_storage")}:</strong> {formatStorage(plan.vector_storage)}
                                        </Typography>
                                        {plan.raster_storage > 0 && (
                                            <Typography variant="body2" align="center" style={{ marginBottom: '10px' }}>
                                                <strong>{t("raster_storage")}:</strong> {formatStorage(plan.raster_storage)}
                                            </Typography>
                                        )}
                                        {plan.name !== 'Custom' ? (
                                            <Button
                                                variant="contained"
                                                fullWidth
                                                color="primary"
                                                style={{ marginTop: '20px', borderRadius: '20px' }}
                                                onClick={() => navigate((plan.url==="free"?"/login":`/subscribe/${plan.url}`))}
                                            >
                                                Subscribe
                                            </Button>
                                        ) : (
                                            <Button
                                                variant="outlined"
                                                fullWidth
                                                color="secondary"
                                                style={{ marginTop: '20px', borderRadius: '20px' }}
                                                onClick={() => navigate('/contact')}
                                            >
                                                {plan.price}
                                            </Button>
                                        )}
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Container>
            </div>
            <Footer />
        </>
    );
};

export default Pricing;
