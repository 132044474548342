import React, { useState, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { Drawer, IconButton, List, ListItem, Button, ListItemIcon, ListItemText, Divide, Typography, Box, Divider, Checkbox, FormControlLabel } from '@mui/material';
import { ChevronLeft, ChevronRight, CloudUpload, Layers, Search, Home, Language } from '@mui/icons-material';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import DrawIcon from '@mui/icons-material/Draw';
import { styled } from '@mui/system';
import { handleRaster, handleGeojson, handleDrawUpload } from './eventHandler';
import ToggleLayersSelector from '../sidenav/ToggleLayersSelector';
import { Link } from 'react-router-dom';
import DrawOption from '../sidenav/Draw';
import Tooltip from '@mui/material/Tooltip';
import SearchOption from '../sidenav/SearchOption';

const drawerWidth = 360;
const miniSidebarWidth = 60;
const headerHeight = 70;

const url = process.env.PUBLIC_URL;

const Sidebar = styled(Drawer)(({ open }) => ({
  width: open ? drawerWidth : 0,
  flexShrink: 0,
  position: 'absolute',
  left: miniSidebarWidth,
  top: 0,
  height: '100vh',
  '& .MuiDrawer-paper': {
    width: open ? drawerWidth - miniSidebarWidth : 0,
    transition: 'width 0.3s ease-in-out',
    overflowX: 'hidden',
    position: 'absolute',
    left: 0,
    top: 0,
    height: '100vh',
  },
}));

const MiniSidebar = styled(Box)({
  width: miniSidebarWidth,
  backgroundColor: '#dcdcdc',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  position: 'fixed',
  left: 0,
  height: '100vh',
  zIndex: 1300,
});

const ToggleButton = styled(IconButton)(({ open }) => ({
  position: 'fixed',
  top: 15,
  left: open ? drawerWidth + 10 : miniSidebarWidth + 10,
  transform: open ? 'translateX(-50%)' : 'translateX(-50%)',
  transition: 'left 0.3s ease-in-out',
  zIndex: 1400,
  color: '#000',
  backgroundColor: '#ababab',
  '&:hover': {
    backgroundColor: '#e0e0e0',
  },
}));

export default function SideNav({
  rasters,
  setRasters,
  vectors,
  setVectors,
  geojsonLayerRefs,
  mapInstance,
  selectedFeatureAttributes,
  projectid,
  setUploading,
  changeStyleData,
  setChangeStyleData,
  handleDownload,
  handleDownloadRaster,
  handleDownloadSelected,
  featureGroupRef,
  inmemory,
  open,
  setOpen,
  selectedFeatures,
  setSelectedFeatures,
  rastersOnTop,
  setRastersOnTop
}) {
  const [activeSection, setActiveSection] = useState('addData');
  const dispatch = useDispatch()

  const fileInputRef = useRef(null);
  const rasterInputRef = useRef(null);

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  const handleFileClickRaster = () => {
    rasterInputRef.current.click();
  };

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const setActiveLayer = (layer) => {
    setActiveSection(layer)
    setOpen(true)
  }

  return (
    <>
      <MiniSidebar>
        <List sx={{ marginTop: 10 }}>
          <ListItem key="addData" onClick={() => setActiveLayer('addData')} sx={{ cursor: 'pointer' }}>
            <Tooltip title={"Add Data"} placement="right" >
            <ListItemIcon sx={{ color: 'black', marginBottom: 1 }}>
              <PlaylistAddIcon fontSize='large' />
            </ListItemIcon>
            </Tooltip>
          </ListItem>
          <ListItem key="layers" onClick={() => setActiveLayer('layers')} sx={{ cursor: 'pointer' }} >
            <ListItemIcon sx={{ color: 'black', marginBottom: 1 }}>
              <Layers fontSize='large' />
            </ListItemIcon>
          </ListItem>
          {/* <ListItem
            key="search"
            onClick={() => setActiveLayer('search')}
            sx={{ cursor: 'pointer' }}
          >
            <ListItemIcon sx={{ color: 'black', marginBottom: 1 }}>
              <Search fontSize='large' />
            </ListItemIcon>
          </ListItem> */}
          <ListItem
            key="draw"
            onClick={() => !inmemory && setActiveLayer('draw')}
          >
            <Tooltip title={!inmemory ? "Draw" : "This action is only allowed for logged users"} 
              placement="right" 
              arrow>
              <ListItemIcon sx={{ color: !inmemory?'black':"gray", marginBottom: 1 }}>
                <DrawIcon fontSize="large" />
              </ListItemIcon>
            </Tooltip>
          </ListItem>



        </List>
        <List>
          <ListItem key="home" component={Link} to="/">
            <ListItemIcon sx={{ color: 'black', marginBottom: 1 }}>
              <Home fontSize='large' />
            </ListItemIcon>
          </ListItem>
          <ListItem key="language">
            <ListItemIcon sx={{ color: 'black', marginBottom: 1 }}>
              <Language fontSize='large' />
            </ListItemIcon>
          </ListItem>
        </List>
      </MiniSidebar>

      <Sidebar variant="permanent" open={open}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            height: headerHeight,
            backgroundColor: '#dcdcdc',
            transition: 'padding-left 0.3s ease-in-out',
            paddingLeft: 2
          }}
        >
          <img src={url + "/logo-world.svg"} alt="Logo" style={{ width: 50, height: 50, marginRight: 20 }} />
          {open && (
            <Typography variant="h5" noWrap sx={{ fontWeight: 'bold', fontSize: '1.4rem', marginLeft: 1 }}>
              WebGIS Project
            </Typography>
          )}
        </Box>
        <Divider />
        <List sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          {activeSection === 'layers' && (
            <ListItem sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography variant="h6" sx={{ textAlign: 'center', width: '100%', fontWeight: 'bold' }}>
                Layers
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={rastersOnTop}
                    onChange={(e) => setRastersOnTop(e.target.checked)}
                    color="primary"
                  />
                }
                label="Rasters on top"
                sx={{ alignSelf: 'flex-start', mt: 1 }}
              />
              <ToggleLayersSelector
                rasters={rasters}
                setRasters={setRasters}
                vectors={vectors}
                setVectors={setVectors}
                geojsonLayerRefs={geojsonLayerRefs}
                mapInstance={mapInstance}
                selectedFeatureAttributes={selectedFeatureAttributes}
                changeStyleData={changeStyleData}
                setChangeStyleData={setChangeStyleData}
                handleDownload={handleDownload}
                handleDownloadRaster={handleDownloadRaster}
                handleDownloadSelected={handleDownloadSelected}
                inmemory={inmemory}
                projectId={projectid}
                selectedFeatures={selectedFeatures}
                setSelectedFeatures={setSelectedFeatures}
                rastersOnTop={rastersOnTop}
                setRastersOnTop={setRastersOnTop}
              />
            </ListItem>
          )}
          {/* {activeSection === 'search' && (
            <ListItem sx={{ width: '100%' }}>
              <Typography variant="h6" sx={{ textAlign: 'center', width: '100%', fontWeight: 'bold' }}>
                Search
              </Typography>

              <SearchOption
                vectors={vectors}
              />
            </ListItem>
          )} */}
          {activeSection === 'draw' && (
            <ListItem sx={{ 
              width: '100%', 
              display: 'flex', 
              flexDirection: 'column', 
              alignItems: 'center' 
              }}>

              <Typography variant="h6" sx={{ 
                  textAlign: 'center', 
                  width: '100%', 
                  fontWeight: 'bold' 
                }}>
                Draw Vector
              </Typography>

              <div id="draw-toolbar-container" style={{ marginTop: '10px' }}>
                <DrawOption
                  map={mapInstance}
                  setVectors={setVectors}
                  projectid={projectid}
                  setUploading={setUploading}
                />
              </div>
            </ListItem>
          )}
          <ListItem sx={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Box 
              // sx={{ width: '80%', textAlign: 'center' }}
              >
              { activeSection === 'addData' && (
                <>
                  <Typography variant="h6" sx={{ textAlign: 'center', width: '100%', fontWeight: 'bold' }}>
                    Add Data
                  </Typography>
                  <input
                    type="file"
                    onChange={(event) => handleGeojson(
                      event,
                      setVectors,
                      mapInstance,
                      dispatch,
                      projectid,
                      setUploading,
                      inmemory
                    )}
                    ref={fileInputRef}
                    style={{ display: 'none' }}
                    accept=".geojson, application/geo+json, .zip, .kml, .gpkg, application/vnd.google-earth.kml+xml, application/geopackage+sqlite3"
                  />
                  <Tooltip title={"(GeoJSON, KML, GPKG)"} placement="right" >
                    <Button
                      variant="contained"
                      sx={{
                        width: '100%',
                        marginBottom: '15px',
                        backgroundColor: '#264653',
                        border: '1px solid #000',
                        borderRadius: '28px',
                        boxShadow: 'none',
                        color: '#fff',
                        '&:hover': {
                          backgroundColor: '#666',
                        }
                      }}
                      startIcon={<FileUploadIcon />}
                      onClick={handleFileClick}
                    >
                      Vector
                    </Button>
                  </Tooltip>
                  {!inmemory?
                  <>
                  <input
                    type="file"
                    onChange={(event) => handleRaster(
                      event,
                      setRasters,
                      mapInstance,
                      dispatch,
                      projectid,
                      setUploading
                    )}
                    ref={rasterInputRef}
                    style={{ display: 'none' }}
                    accept=".tif"
                  />
                  <Tooltip title={"(GeoTIFF)"} placement="right" >
                    <Button
                      variant="contained"
                      sx={{
                        width: '100%',
                        backgroundColor: '#14213D',
                        border: '1px solid #999',
                        borderRadius: '28px',
                        boxShadow: 'none',
                        color: '#FFFFFF',
                        '&:hover': {
                          backgroundColor: '#ccc',
                        }
                      }}
                      startIcon={<FileUploadIcon />}
                      onClick={handleFileClickRaster}
                    >
                      Raster
                    </Button>
                  </Tooltip>
                  </>
                  :null
                  }
                </>
              )}
            </Box>
          </ListItem>
        </List>

        <ToggleButton onClick={handleDrawerToggle} open={open}>
          {open ? <ChevronLeft /> : <ChevronRight />}
        </ToggleButton>
      </Sidebar>
    </>
  );
}