import React, { useState, useEffect, useRef } from 'react';
import Cookies from 'js-cookie'
import axios from 'axios';


import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Button,
    TextField,
    Paper,
    Tooltip
} from '@mui/material';


const API_URL = process.env.REACT_APP_API_URL || 'http://127.0.0.1:8000/'


function AttributesModal({ 
    open, 
    onClose, 
    modalData,
    setModalData, 
    uniqueKeys, 
    id, 
    setVectors,
    inmemory
}) {
    const flattenedData = modalData.flat()
    const [isEditMode, setIsEditMode] = useState(false);
    const [editableData, setEditableData] = useState([...flattenedData]);
    const [originalData, setOriginalData] = useState([...flattenedData]);

    useEffect(() => {
        const flattenedData = modalData.flat();
        setEditableData([...flattenedData]);
        setOriginalData([...flattenedData]); 
    }, [modalData]);

    
    const handleInputChange = (event, index, key) => {
        const { value } = event.target;
        const updatedData = [...editableData];
        updatedData[index] = { ...updatedData[index], [key]: value };
        setEditableData(updatedData);
    };

    const handleCancel = ()=>{
        setEditableData([...originalData]); 
        setIsEditMode(false)
    }

    // Toggle edit mode
    const toggleEditMode = () => {
        if (!inmemory){
            setIsEditMode(!isEditMode);
        }
    };

    // Save the changes
    const handleSave = async () => {
        const token = Cookies.get('access_token');

        try {
            const response = await axios.post(
                `${API_URL}api/main/update/feature/${id}/`,
                {
                    data: editableData,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`,
                    },
                }
            );

            if (response.status === 200) {
                setIsEditMode(false);
                setVectors((vectors) => {
                    return vectors.map((vector) => {
                        if (vector.data && vector.data.features) {
                            const updatedFeatures = vector.data.features.map((feature) => {
                                if (feature.id === id) {
                                    return {
                                        ...feature,
                                        properties: {
                                            ...feature.properties,
                                            attributes: {
                                                ...feature.properties.attributes,
                                                ...response.data.attributes,
                                            },
                                        },
                                    };
                                }
                                return feature;
                            });
                            return {
                                ...vector,
                                data: {
                                    ...vector.data,
                                    features: updatedFeatures,
                                },
                            };
                        }
                        return vector;
                    });
                });
                setModalData([...editableData]);

            } else {
                console.error('Failed to save data:', response.statusText);
            }
        } catch (error) {
            console.error('Error saving data:', error);
        }
    };

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
            <DialogTitle>Tabela de Atributos</DialogTitle>
            <DialogContent>
                <TableContainer component={Paper}>
                    <Table>
                        {editableData.map((item, index) => (
                            <TableBody key={index}>
                                {uniqueKeys.map((key) => (
                                    <TableRow key={key}>
                                        <TableCell 
                                            component="th" 
                                            scope="row">
                                            {key}
                                        </TableCell>
                                        <TableCell>
                                            {isEditMode ? (
                                                <TextField
                                                    value={
                                                        item[key] || ''}
                                                    onChange={
                    (event) => handleInputChange(event, index, key)}
                                                    fullWidth
                                                />
                                            ) : (
                                                item[key] || '—'
                                            )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        ))}
                    </Table>
                </TableContainer>
            </DialogContent>
            <DialogActions>
                {isEditMode ? (
                    <>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                    <Button onClick={handleCancel} color="primary">
                        Cancel
                    </Button>
                    </>
                ) : (
                    <Tooltip title={"Only allowed for logged users"} placement="top">
                        <span>
                            <Button disabled={inmemory} 
                                    onClick={toggleEditMode} color="primary">
                                Edit
                            </Button>
                        </span>
                    </Tooltip>
                )}
                <Button onClick={onClose} color="primary">
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    );
}



export default AttributesModal