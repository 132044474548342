import React, { useState } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Button,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    MenuItem,
    TextField,
    Select,
    InputLabel,
    FormControl,
    Tooltip
} from '@mui/material';
import { Search } from "@mui/icons-material";
import { TableChart } from '@mui/icons-material';

const comparisonOperators = [
    { label: 'Equal to', value: '=' },
    { label: 'Larger than', value: '>' },
    { label: 'Larger or equal to', value: '>=' },
    { label: 'Smaller than', value: '<' },
    { label: 'Smaller or equal to', value: '<=' }
];

const SearchOption = ({ columns, rows, setFilteredRows, onClose }) => {
    const [selectedColumn, setSelectedColumn] = useState('');
    const [selectedOperator, setSelectedOperator] = useState('=');
    const [inputValue, setInputValue] = useState('');

    const handleSearch = () => {
        const isTextField = rows.some(row => typeof row[selectedColumn] === 'string');
        const filtered = rows.filter((row) => {
            const columnValue = row[selectedColumn];
            if (isTextField && selectedOperator !== '=') {
                return false;  // Prevent non-equal comparisons for text fields
            }
            switch (selectedOperator) {
                case '=':
                    return columnValue === inputValue;
                case '>':
                    return columnValue > inputValue;
                case '>=':
                    return columnValue >= inputValue;
                case '<':
                    return columnValue < inputValue;
                case '<=':
                    return columnValue <= inputValue;
                default:
                    return true;
            }
        });
        setFilteredRows(filtered);
        onClose(); // Close the search options after searching
    };

    return (
        <>
            <FormControl fullWidth margin="normal">
                <InputLabel>Column</InputLabel>
                <Select
                    value={selectedColumn}
                    onChange={(e) => setSelectedColumn(e.target.value)}
                >
                    {columns.map((column) => (
                        <MenuItem key={column} value={column}>
                            {column}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <FormControl fullWidth margin="normal">
                <InputLabel>Operator</InputLabel>
                <Select
                    value={selectedOperator}
                    onChange={(e) => setSelectedOperator(e.target.value)}
                    disabled={rows.some(row => typeof row[selectedColumn] === 'string')}
                >
                    {comparisonOperators.map((operator) => (
                        <MenuItem key={operator.value} value={operator.value}>
                            {operator.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>

            <TextField
                fullWidth
                margin="normal"
                label="Value"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
            />


            {columns.length>0?
            <Button
                onClick={handleSearch}
                variant="contained"
                color="primary"
            >Search</Button>
            :null
            }
        </>
    );
};

export const AttributeTableModal = ({ open, onClose, vector, selectedFeatures, setSelectedFeatures }) => {
    const [filteredRows, setFilteredRows] = useState(vector.data.features);
    const [searchOpen, setSearchOpen] = useState(false);

    const handleRowClick = (feature) => {
        handleSelectFeature(feature);
    };

    const handleSelectFeature = (feature) => {
        const featureId = feature.id;
        setSelectedFeatures(prevSelectedFeatures => {
            const isSelected = prevSelectedFeatures.includes(featureId);
            return isSelected
                ? prevSelectedFeatures.filter(id => id !== featureId)
                : [...prevSelectedFeatures, featureId];
        });
    };

    const handleSearchIconClick = () => {
        setSearchOpen(true);
    };

    const handleSearchClose = () => {
        setSearchOpen(false);
    };

    // Extract column names dynamically from feature properties
    const columns = Object.keys(vector.data.features[0].properties.attributes);

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle>
                Attribute Table
                {
                columns.length>0?
                <Button color="primary" onClick={handleSearchIconClick}>
                    <Search />
                </Button>
                :null
                }
            </DialogTitle>
            <DialogContent>
                {searchOpen && (
                    <SearchOption
                        columns={columns}
                        rows={vector.data.features.map(f => f.properties)}
                        setFilteredRows={setFilteredRows}
                        onClose={handleSearchClose}
                    />
                )}
                <Table>
                    <TableHead>
                        <TableRow>
                            {columns.map((column) => (
                                <TableCell key={column}>{column}</TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredRows.map((feature) => (
                            <TableRow
                                key={feature.id}
                                onClick={() => handleRowClick(feature)}
                                selected={selectedFeatures.includes(feature.id)}
                                style={{ cursor: 'pointer' }}
                            >
                                {columns.map((column) => {
                                    const value = feature.properties.attributes[column];
                                    return (
                                        <TableCell key={column}>
                                            {typeof value === 'object' ? JSON.stringify(value) : value}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        ))}
                    </TableBody>

                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export const VectorModalButton = ({ 
    vector, 
    selectedFeatures, 
    setSelectedFeatures,
    inmemory=false,
}) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <>
            <Tooltip 
                title={inmemory ? "Function only allowed for logged users" : "A"} 
                placement="right"
            >   
                <span>
                    <MenuItem onClick={handleOpen} disabled={inmemory}>
                        <TableChart />
                        <span style={{ marginLeft: 8 }}>Attribute table</span>
                    </MenuItem>
                </span>
            </Tooltip>
            <AttributeTableModal
                open={open}
                onClose={handleClose}
                vector={vector}
                selectedFeatures={selectedFeatures}
                setSelectedFeatures={setSelectedFeatures}
            />
        </>
    );
};

export default VectorModalButton;
